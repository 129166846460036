'use client';

import { ImageSkeleton, RectangleSkeleton } from '@fe-common-ui/Skeleton';
import { SCREEN_SIZE } from '@fe-common-utils/constants/screen';
import { isClient } from '@fe-common-utils/libs/utils';
import { styled } from '@linaria/react';
import { memo, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import { DEVICE } from '../../constants/device';
import { refresh, unmountSlots } from '../../utils/googletag';

const CloseContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CloseWrapper = styled.div`
  position: relative;
`;

const CloseButton = styled.button<{ buttonRight?: number; buttonTop?: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: ${({ buttonRight }) => (buttonRight ? `${buttonRight}px` : '0px')};
  top: ${({ buttonTop }) => (buttonTop ? `${buttonTop}px` : '0px')};
  text-align: center;
  font-size: 20px;
  color: #515c69;
  background-color: rgb(255 255 255 / 0.3);
  border: 1px solid #e2e8f1;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  z-index: 50;
`;

interface CloseContainerProps {
  isEnable?: boolean;
  isDefaultOpen?: boolean;
  isButtonHidden?: boolean;
  buttonRight?: number;
  buttonTop?: number;
  children?: ReactNode;
  isAdLoaded?: boolean;
}

const CloseContainer = ({
  isEnable = false,
  isDefaultOpen = true,
  isButtonHidden = false,
  buttonRight = 0,
  buttonTop = 0,
  children
}: CloseContainerProps) => {
  if (!isEnable) return children;

  const [isOpen, setIsOpen] = useState(isDefaultOpen);

  const onClickModalCloseHandler = useCallback(() => {
    setIsOpen(false);
  }, []);

  if (!isOpen) return null;

  return (
    <CloseContent>
      <CloseWrapper>
        {!isButtonHidden && (
          <CloseButton
            role='button'
            className='modalClose'
            aria-label='close'
            buttonRight={buttonRight}
            buttonTop={buttonTop}
            onClick={onClickModalCloseHandler}
          >
            x
          </CloseButton>
        )}
        {children}
      </CloseWrapper>
    </CloseContent>
  );
};

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    googletag: any;
  }
}

export const AD_RENDERED_EVENT = 'google-ad-slot-rendered';
export const AD_EMPTY_EVENT = 'google-ad-is-empty';
const WAIT_TIME_TO_CHECK_AD = 250;

const AdContainer = styled.div<{
  adWidth: number | string;
  adHeight: number | string;
  adMinWidth: number | undefined;
  adMinHeight: number | undefined;
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  device?: string;
}>`
  width: ${({ adWidth }) => (adWidth ? (typeof adWidth === 'number' ? `${adWidth}px` : adWidth) : 'auto')};
  height: ${({ adHeight }) => (adHeight ? (typeof adHeight === 'number' ? `${adHeight}px` : adHeight) : 'auto')};
  min-width: ${({ adMinWidth }) => (adMinWidth ? `${adMinWidth}px` : 'auto')};
  min-height: ${({ adMinHeight }) => (adMinHeight ? `${adMinHeight}px` : 'auto')};
  margin-top: ${props => props.marginTop || 0}px;
  margin-bottom: ${props => props.marginBottom || 0}px;
  margin-left: ${({ marginLeft }) => (marginLeft ? `${marginLeft}px` : 'auto')};
  margin-right: ${({ marginRight }) => (marginRight ? `${marginRight}px` : 'auto')};
  line-height: 0;
  font-size: 0;
  overflow: hidden;

  display: ${({ device }) => (device === DEVICE.MOBILE ? 'none' : 'block')};

  @media screen and (max-width: ${SCREEN_SIZE.DESKTOP}px) {
    display: block;
  }
`;

export type Profile = {
  name: string;
  path: string;
  size: (number | string)[][];
  className?: string;
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  device?: string; // 'mobile' | 'desktop' 只在特定裝置上顯示廣告
  checkAdIsLoaded?: boolean; // 載入後x秒會檢查是否有iframe元素，如果沒有會執行refresh重載廣告
  enabledDefaultHeight?: boolean; // 是否顯示預設高度
  isLoadImmediately?: boolean; // 新增prop: 是否立即載入廣告
};

interface AdSlotProps {
  profile: Profile | undefined;
  isSkeleton?: boolean;
  close?: CloseContainerProps;
}

const AdSlot = ({ profile, close, isSkeleton = true }: AdSlotProps) => {
  const slot = useRef(null);
  const adContainerRef = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef<NodeJS.Timeout[]>([]);
  const [isAdLoaded, setIsAdLoaded] = useState(true);
  const hasInitialized = useRef(false);

  if (!profile) return null;

  const {
    name,
    size,
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
    device,
    checkAdIsLoaded,
    enabledDefaultHeight = true,
    isLoadImmediately = false
  } = profile;

  // 清理所有 timeout
  const clearTimeouts = () => {
    timeoutRef.current.forEach(clearTimeout);
    timeoutRef.current = [];
  };

  // 過 250 毫秒後檢查廣告是否載入
  const onCheckAd = useCallback(
    (node: HTMLDivElement) => {
      let checkCount = 0;
      const maxChecks = 3;

      const checkAdContent = () => {
        if (checkCount >= maxChecks) {
          console.log('Max ad check attempts reached for:', profile?.name ? `div-gpt-ad-${profile.name}` : 'unknown');
          return;
        }

        const timeout = setTimeout(() => {
          if (node && !node?.querySelector('iframe')) {
            refresh(slot.current);
            checkCount++;
            checkAdContent(); // 遞迴檢查
          }
        }, WAIT_TIME_TO_CHECK_AD);

        timeoutRef.current.push(timeout);
      };

      checkAdContent();
    },
    [profile?.name]
  );

  const initializeAd = useCallback(() => {
    if (!isClient || !profile || hasInitialized.current) return;

    hasInitialized.current = true;

    const googletag = window.googletag || null;
    if (!googletag) return;

    const { name, path, size } = profile;
    const slotId = `div-gpt-ad-${name}`;

    googletag.cmd?.push(function () {
      try {
        // 先移除舊的事件監聽
        googletag.pubads().removeEventListener('slotRenderEnded', handleSlotRenderEnded);
        googletag.pubads().addEventListener('slotRenderEnded', handleSlotRenderEnded);

        // 獲取現有的slot
        const existingSlot = googletag
          .pubads()
          .getSlots()
          .find((s: { getSlotElementId: () => string }) => s.getSlotElementId() === slotId);
        if (existingSlot) {
          googletag.destroySlots([existingSlot]);
        }

        // 清除舊的DOM
        const container = document.getElementById(slotId);
        if (container) {
          container.innerHTML = '';
        }

        // 重新定義slot
        slot.current = googletag?.defineSlot(path, size, slotId)?.addService(googletag.pubads());

        if (!slot.current) {
          console.error('Failed to define ad slot:', slotId);
          return;
        }

        googletag.enableServices();

        // 確保DOM準備好後再顯示廣告
        const timeout = setTimeout(() => {
          googletag.display(slotId);
        }, 0);
        timeoutRef.current.push(timeout);
      } catch (error) {
        console.error('Error in ad initialization:', error);
      }
    });
  }, [profile]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSlotRenderEnded = useCallback(
    (event: any) => {
      if (!profile) return;

      const slotId = `div-gpt-ad-${profile.name}`;
      if (event.slot.getSlotElementId() === slotId) {
        if (event.isEmpty) {
          setIsAdLoaded(false);
          window.dispatchEvent(
            new CustomEvent(AD_EMPTY_EVENT, {
              detail: slotId
            })
          );
          return;
        }

        if (isClient) {
          window.dispatchEvent(new CustomEvent(AD_RENDERED_EVENT, { detail: slotId }));
        }
      }
    },
    [profile]
  );

  // 移除 IntersectionObserver 相關的程式碼，改用 useEffect 直接初始化
  useEffect(() => {
    if (!isClient || !profile) return;

    // 重置初始化標記
    hasInitialized.current = false;

    // 直接初始化廣告，不需要等待可見性
    const timeout = setTimeout(() => {
      initializeAd();
    }, 0);

    timeoutRef.current.push(timeout);

    return () => {
      clearTimeouts();
      // 清理googletag事件
      if (isClient) {
        const googletag = window.googletag || null;
        if (googletag) {
          googletag.cmd.push(function () {
            googletag.pubads().removeEventListener('slotRenderEnded', handleSlotRenderEnded);
          });
        }
        // 卸載slots
        unmountSlots(slot?.current);
      }
    };
  }, [profile, initializeAd]);

  const slotId = `div-gpt-ad-${name}`;

  // 預設先拿第一組size作為廣告容器的大小
  const [adMinWidth, adMinHeight] = size?.[0] ?? [];
  const [width, height] = (enabledDefaultHeight && size?.[0]) || [];

  // 廣告會有二種類型，一種是橫幅廣告，這時就會指定多種尺寸，另一種是文字型廣告，這樣尺寸類型會是 `fluid`，隨著容器大小而變化
  // 所以這邊要判斷廣告類型，如果是橫幅廣告，就用指定的尺寸，如果是文字型廣告，就用容器的大小
  const isBannerAd = typeof width === 'number' && width > 0 && typeof height === 'number' && height > 0;
  const adWidth = isBannerAd ? width : '100%';
  const adHeight = isBannerAd ? height : '100%';

  // 橫幅廣告類型基礎是圖片居多，所以用圖片骨架，文字型廣告的矩形骨架
  const Skeleton = isBannerAd ? ImageSkeleton : RectangleSkeleton;

  const AdContent = () => {
    return (
      <AdContainer
        ref={adContainerRef}
        id={slotId}
        adWidth={adWidth}
        adHeight={adHeight}
        adMinWidth={Number(adMinWidth)}
        adMinHeight={Number(adMinHeight)}
        marginTop={marginTop}
        marginBottom={marginBottom}
        marginLeft={marginLeft}
        marginRight={marginRight}
        device={device}
        data-ad-status="loading"
      >
        {checkAdIsLoaded && <div ref={onCheckAd} style={{ width: '100%', height: '100%' }} />}
        {isSkeleton && <Skeleton width='100%' height='100%' />}
      </AdContainer>
    );
  };

  return (
    <>
      {close?.isEnable && isAdLoaded && (
        <CloseContainer {...close}>
          <AdContent />
        </CloseContainer>
      )}
      {!close?.isEnable && isAdLoaded && <AdContent />}
    </>
  );
};

export default memo(AdSlot);
